<template>
  <a-modal :title="ModalTitle" v-model="visible" :confirmLoading="confirmLoading"
    cancelText="取消" okText="保存" width="22.5%" @ok="handleOk" @cancel="handleCancel">
    <a-form :form="form">

      <a-form-item label="上传发票" :label-col="labelCol" :wrapper-col="{ span: 8 }">
        <a-upload v-decorator="rules.filePath" name="file" 
          :data="uploadParams"
          :action="uploadUrl" 
          accept='.pdf'
          :beforeUpload="beforeUpload"
          :fileList="fileList"
          @change="changeFile">
          <a-button>
            <a-icon type="upload" /> 点击上传
          </a-button>
        </a-upload>
      </a-form-item>

    </a-form>
  </a-modal>
</template>

<script>
import { mapState } from 'vuex'
import url from '@/utils/URL'
import createFilePath from '@/utils/tools'

export default {
  name: 'fileModal',
  inject: ['parent'],
  props: {
    item: Object,
  },

  data() {
    return {
      labelCol: { span: 5 },
      confirmLoading: false,
      visible: false,
      ModalTitle: '',

      form: this.$form.createForm(this),
      rules: {
        filePath: [ 'invoice_pdf',
          {rules: [{ required: true, message: '请上传发票!' }]}
        ],
      },
      uploadUrl: url.uploadQiNiu, 
      uploadParams: {},
      fileList: []
    }
  },

  async created() {
    this.visible = true
    this.ModalTitle = `上传发票 (${this.item.student_name})`
  },

  methods: {
    async handleOk() {
      try {
        const { item, form } = this
        let params = await form.validateFields()
        if(this.fileList[0].status != 'done'){
          this.$message.warning('请等待文件上传!!')
          return false
        }
        this.confirmLoading = true
        if (params) {
          params.invoice_pdf = `http://v01.wedots.cn/${this.fileList[0].name}`
          params.apply_id = this.item.apply_id
          params.invoice_status = this.item.invoice_status
          await this.$store.dispatch('huidongCheckAction', params)
          .then(res=>{
            this.$message.success('操作成功~')
            this.parent.hideFileModal(1)
          })
          .catch(err=>{
            this.confirmLoading = false
          })
        }
      } catch {
      }
			
    },
    handleCancel() {
      this.parent.hideFileModal()
    },

    async beforeUpload(file) {
      if (!file) { return false }
      let res = await this.$store.dispatch('massTokenAction', {})
      if (res) {
        this.uploadParams.token = res.data.uptoken
        this.uploadParams.key = createFilePath(file.name)
        this.fileList = []
        return true
      }
      return false
    },
    changeFile(file) {
      if(file.fileList[0].status != 'done'){
        this.fileList = file.fileList
      }else{
        this.fileList[0] = {
          uid: '1',
          name: this.uploadParams.key,
          status: 'done',
          size:file.file.size
        }
        this.fileList[0].name = this.uploadParams.key
      }
    }
  }
}
</script>
