<template>
    <div>
        <div class="main-box">
            <div class="clearfix table-tools">
                <div class="search"></div>
                <div class="buttons">
                    <a-form layout='inline'>
                        <a-form-item>
                            <a-button icon="plus" type="primary" @click="showEditModal()">填写日报</a-button>
                        </a-form-item>
                    </a-form>
                </div>
            </div>
            <div class="table">
                <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
                <a-table :pagination="false" :bordered='true' rowKey="studio_id"
                :columns="columns" :dataSource="list">
                </a-table>
            </div>
            <!-- <div class="page">
                <a-pagination
                :pageSizeOptions="pageSizeOptions"
                :total="pageParams.totalCount"
                showSizeChanger
                :pageSize="pageParams.perPage"
                v-model="current"
                @change="changePage"
                @showSizeChange="onShowSizeChange"
                :showTotal="total => `共 ${pageParams.totalCount.toLocaleString()}条 `"
                >
                    <template slot='buildOptionText' slot-scope='props'>
                    <span v-if="props.value!=='100'">{{props.value}}条/页</span>
                    <span v-if="props.value==='100'">100条/页</span>
                    </template>
                </a-pagination>
            </div> -->
        </div>
    </div>
</template>

<script>
const columns = [
  { title: '校区', dataIndex: 'studio_name', key: 'studio_name',align:'center'},
  { title: '本周跟进量', children:[
      {title: 'TOTAL',dataIndex: 'total_follow',key: 'total_follow',align:'center'},
      {title: '热例子',dataIndex: 'hot_count',key: 'hot_count',align:'center'},
      {title: '冷例子',dataIndex: 'cold_count',key: 'cold_count',align:'center'},
      {title: 'TMK',dataIndex: 'tmk_count',key: 'tmk_count',align:'center'},
  ]},
  { title: '本周访诺量', children:[
      {title: '周访诺量',dataIndex: 'promise_count',key: 'promise_count',align:'center'},
      {title: '周访诺数【预约本周】',dataIndex: 'promise_this_week_count',key: 'promise_this_week_count',align:'center'},
      {title: '周访诺数【无访诺日期】',dataIndex: 'promise_next_week_count',key: 'promise_next_week_count',align:'center'},
  ]},
]
    export default {
        name:'campusDaily',
        data() {
            return {
                loading: false,
                columns,
                list:[],
                isEditModal:false,
            }
        },
        created(){
            this.getList()
        },
        methods: {
            async getList(){
                this.loading = true
                let res = await this.$store.dispatch('huidongReportStudioWeekAction')
                this.list = res.data
                this.loading = false
            },
        },
    }
</script>

<style lang="scss" scoped>

</style>