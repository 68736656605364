<template>
    <div>
        <a-drawer
        placement="right"
        :visible="visible"
        @close="onClose"
        :mask='false'
        wrapClassName="huidongDrawer"
        >
            <div class="huidongFollow">
                <div class="huidongFollow-nav">
                    <a-row>
                        <a-col :span="6">
                            <span style="font-size:24px">
                                <span>{{detaildata.nickname}}</span>
                            </span>
                        </a-col>
                        <a-col style="text-align:right;padding-right:20px" :span="11" :offset="6">
                            <a-button v-if="detaildata.is_notify !=1" @click="showEditModal" type="primary">编辑</a-button>
                        </a-col>
                    </a-row>
                </div>
                <a-form>
                  <a-form-item label="付款凭证">
                      <div>
                          <LImg style="height:100px;margin:5px" preview="0" v-for="(image, index) of detaildata.screen_shot" :key="index" :src="image" />
                      </div>
                  </a-form-item>
                  <a-form-item label="家长签名">
                      <LImg style="height:100px" preview="1" :src="detaildata.signature" />
                  </a-form-item>
                  <a-form-item label="附件">
                      <div>
                          <LImg style="height:100px;margin:5px" preview="1" v-for="(image, index) of detaildata.student_attachment" :key="index" :src="image" />
                      </div>
                  </a-form-item>
                </a-form>
            </div>
        </a-drawer>
        <editModal v-if="isEditModal" :item='detaildata' />
    </div>
</template>

<script>
    import editModal from './editModal'
    export default {
        name:"detailModal",
        inject: ['parent'],
        provide() {
            return {
                parent: this
            }
        },
        data() {
            return {
                visible: false,
                isEditModal: false,
                detaildata:{}
            }
        },
        components: {
            editModal,
        },
        props:{
            item:Number,
        },
        created(){
            this.visible = true
            this.getDetail()
        },
        methods:{
            async getDetail(){
                let res = await this.$store.dispatch('huidongApplyDetailAction', {apply_id:this.item})
                this.detaildata = res.data
            },
            onClose(){
                this.parent.hideDetailModal()
            },
            showEditModal(){
                this.isEditModal = true
            },
            hideEditModal(val){
                if(val){
                    this.parent.getList()
                    this.getDetail()
                }
                this.isEditModal = false
            },

        },
        watch: {
            item(newValue, oldValue) {
                this.getDetail()
            }
        },
    }
</script>

<style lang="scss">
    .huidongDrawer{
        width: 50% !important;
        .ant-drawer-open{
            width: 50% !important;
        }
        .ant-drawer-content-wrapper{
            width: 100% !important;
        }
        .ant-drawer-mask{
            height: 0 !important;
        }
    }
    .huidongFollow{
        .huidongFollow-nav{
           .ant-btn-primary{
                margin-right: 10px;
            } 
        }
        .huidongFollow-bg{
            background: #e6fff6;
        }
    }
</style>