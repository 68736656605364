<template>
    <div @click="hideDetailModal" style="height:100%">
        <div class="">
            <div class="clearfix table-tools">
                <div class="buttons">
                    <a-form layout='inline'>
                        <a-form-item>
                            <a-button @click="toExport" icon="export" :loading="exportLoading">导出</a-button>
                        </a-form-item>
                    </a-form>
                </div>
                <div class="search">
                    <a-form layout='inline' @submit="searchList">
                    <a-form-item>
                        <a-tooltip placement="topLeft" >
                        <template slot="title">
                            <span>时间筛选</span>
                        </template>
                        <a-range-picker
                            :ranges="rangesData"
                            :value='rangesValue'
                            :disabled-date="disabledDate"
                            @change="(date, dateString)=>onChange(date, dateString,2)" style="width:230px;" />
                        </a-tooltip>
                    </a-form-item>
                    <a-form-item>
                        <a-tooltip placement="topLeft" >
                        <template slot="title">
                            <span>姓名</span>
                        </template>
                        <a-input v-model.trim="searchParams.search.nickname" placeholder="姓名/手机/邮箱/订单号" style="width: 180px"/>
                        </a-tooltip>
                    </a-form-item>
                    <!-- <a-form-item >
                        <a-select v-model="searchParams.search.cc_id" showSearch allowClear placeholder="请选择课程顾问" :filterOption="filterOption" style="width: 160px">
                            <a-select-option v-for="(item, index) of consultants" :key="index" :value="item.course_consultant_id">{{ item.filter_name }}</a-select-option>
                        </a-select>
                    </a-form-item> -->


                    <a-form-item>
                      <a-radio-group :value="searchParams.search.is_invoice">
                        <a-radio-button value="0" @click="handleColButChange">
                            未开票
                        </a-radio-button>
                        <a-radio-button value="1" @click="handleColButChange">
                            已开票
                        </a-radio-button>
                      </a-radio-group>
                    </a-form-item>

                    <a-form-item>
                      <a-radio-group :value="searchParams.search.contract_type">
                        <a-radio-button value="1" @click="handleTypeButChange">
                            新签
                        </a-radio-button>
                        <a-radio-button value="2" @click="handleTypeButChange">
                            续费
                        </a-radio-button>
                      </a-radio-group>
                    </a-form-item>

                    

                    <!-- <a-form-item>
                      <a-radio-group :value="paramsData.contract_type">
                        <a-radio-button @click="handleTypeButChange('-1')" value="-1">
                            付费试听
                        </a-radio-button>
                        <a-radio-button @click="handleTypeButChange('1')" value="1">
                            新签
                        </a-radio-button>
                        <a-radio-button @click="handleTypeButChange('2')" value="2">
                            续费
                        </a-radio-button>
                        <a-radio-button @click="handleTypeButChange('3')" value="3">
                            转介绍
                        </a-radio-button>
                      </a-radio-group>
                    </a-form-item> -->

                    <!-- <a-form-item class="block-line" label="" >
                      <a-select v-model="is_invoice" placeholder="请选择开票状态" @change="handleStatus" style="width: 100px">
                        <a-select-option value="">全部</a-select-option>
                        <a-select-option :value="0">未开票</a-select-option>
                        <a-select-option :value="1">已开票</a-select-option>
                      </a-select>
                    </a-form-item> -->
                    <a-form-item>
                        <a-button type="primary" html-type="submit" @click="searchList">搜索</a-button>
                    </a-form-item>

                    <a-form-item>
                            <a-popover  placement="bottom" trigger="click" v-model="visible">
                            <template slot="content">
                                <div class="more-search" style="width:740px">
                                <a-form layout='inline'>

                                    <a-form-item class="block-line" label="所属校区">
                                        <a-select mode="multiple" v-model='searchParams.search.studio_id'  @change="changeBadge" allowClear showSearch placeholder="请选择校区" :filterOption="filterOption" :maxTagCount=2>
                                        <a-select-option v-for="(d, index) of studios" :key="index" :value="d['studio_id']">{{ d['filter_name'] }}</a-select-option>
                                        </a-select>
                                    </a-form-item>
                                    
                                    <a-form-item class="block-line" label="课程顾问">
                                        <a-select mode="multiple" v-model='searchParams.search.cc_id'  @change="changeBadge" allowClear showSearch placeholder="请选择课程顾问" :filterOption="filterOption" :maxTagCount=2>
                                        <a-select-option v-for="(d, index) of consultants" :key="index" :value="d['course_consultant_id']">{{ d['filter_name'] }}</a-select-option>
                                        </a-select>
                                    </a-form-item>

                                    <!-- <a-form-item class="block-line" label="" >
                                    <a-select v-model="searchParams.search.studio_id" placeholder="请选择校区" allowClear showSearch :filterOption="filterOption" style="width: 160px">
                                        <a-select-option v-for="(d, index) of studios" :key="index" :value="d['studio_id']">{{ d['filter_name'] }}</a-select-option>
                                    </a-select>
                                    </a-form-item>   -->

                                </a-form>
                                <div class="more-search__buttons">
                                    <a-button type="dashed" @click="closeSearch">取消</a-button>
                                    <a-button @click="reset">重置</a-button>
                                    <a-button type="primary" @click="searchList">搜索</a-button>
                                </div>
                                </div>
                            </template>
                                <a-badge :count="badgeCount">
                                    <a-button>更多<a-icon type="down" /></a-button>
                                </a-badge>
                            </a-popover>
                        </a-form-item>

                    </a-form>
                </div>
            </div>
            <div class="table" @click.stop>
                <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
                <a-table :pagination="false" :bordered='false' rowKey="apply_id" @change="handleChange" :customRow="clickRow"
                :columns="columns" :dataSource="list" :scroll="{ x: 2800 }">
                <template slot="index" slot-scope="text, record , index">
                    <span>{{(index+1) + pageParams.perPage * (pageParams.currentPage - 1)}}</span>
                </template>
                <template slot="user" slot-scope="text, record">
                    <a v-if="record.student_id" href="javascript:;" style="display:block" @click.stop="showProfileVisible(record)">{{record.student_name}}</a>
                    <div v-else>{{record.student_name}}</div>
                    <span style="font-size:10px;color: rgba(69, 90, 100, 0.6);">
                            ({{record.student_gender}}{{record.year?','+record.year+'岁':''}})                             
                    </span>
                </template>

                <template slot="cellphone" slot-scope="text,record">
                    <div>
                        {{record.student_cellphone}}
                    </div>
                    <span style="font-size:10px;color: rgba(69, 90, 100, 0.6);" v-if="record.cellphone_geo">
                        ({{record.cellphone_geo.province}},{{record.cellphone_geo.op}})
                    </span>
                </template>

                <template slot="signature" slot-scope="text">
                    <LImg v-if="text" :src="text" style="width: 150px; height: 75px; margin: 0 5px 5px 0"/>
                </template>
                <template slot="image" slot-scope="text">
                    <LImg :preview="0" v-for="(image, index) of text" :key="index" :src="image" style="width: 44px; height: 44px; margin: 0 5px 5px 0"/>
                </template>

                <template slot="is_notify" slot-scope="text">
                    <a-tag v-if='text == 1' color="#00cca2">已推送</a-tag>
                    <a-tag v-else color="yellow">未推送</a-tag>
                </template>


                <template slot="fee" slot-scope="text,record">
                    <div v-if="record.fee_id">{{record.fee_id.fee_title}}</div>
                </template>

                <template slot="coupon" slot-scope="text,record">
                    <div class="coupon_title" v-if="record.coupon_id">{{record.coupon_id.coupon_title}}</div>
                </template>
                <template slot="user_coupon_from" slot-scope="text,record">
                    <div class="coupon_title" v-if="record.user_coupon_from.coupon_from">使用了{{record.user_coupon_from.coupon_from}}转赠的《{{record.user_coupon_from.coupon_title}}》</div>
                </template>

                <template slot="apply_status" slot-scope="text">
                    <div>
                        <span v-if="text == 4">退费</span>
                        <span v-if="text == 2 || text == 3">成功合同</span>
                    </div>
                </template>

                <template slot="is_invoice" slot-scope="text,record">
                    <div @click.stop>
                        <a-switch checkedChildren="已开票" unCheckedChildren="未开票" :checked="text === 1" @change="changeStatus(record)"/>
                    </div>
                </template>

                <template slot="is_shipment" slot-scope="text,record">
                    <div @click.stop>
                        <a-switch checkedChildren="已发货" unCheckedChildren="未发货" :checked="text === 1" @change="changeShipment(record)"/>
                    </div>
                </template>

                <template slot="action" slot-scope="text,record">
                    <a-dropdown>
                        <a class="ant-dropdown-link" @click="e => e.preventDefault()">更多<a-icon type="down" /></a>
                        <a-menu slot="overlay">
                            <a-menu-item @click="pushContract(record)" key="1" v-if="record.fee_id">
                            推送合同
                            </a-menu-item>
                            <a-menu-item @click="showInfoModal(record)" key="2">
                            订单信息
                            </a-menu-item>
                            <a-menu-item v-if="record.invoice_pdf" key="3">
                                <a :href="record.invoice_pdf" target="_blank">发票预览</a>
                            </a-menu-item>
                            <a-menu-item v-if="record.fee_id" key="4">
                                <a href="javascript:;" @click.stop='toPreview(record)'>合同预览</a>
                            </a-menu-item>

                            <a-menu-item v-if="record.contract_pdf" key="8">
                                <a href="javascript:;" @click.stop='toPreviewPDF(record.contract_pdf)'>PDF合同预览</a>
                            </a-menu-item>

                            <a-menu-item @click="showEditModal(record)" key="5">
                                信息编辑
                            </a-menu-item>
                            <a-menu-item @click="toDel(record)" key="6">
                            删除
                            </a-menu-item>
                        </a-menu>
                    </a-dropdown>
                </template>
                </a-table>
            </div>
            <div class="page">
                <a-pagination
                :pageSizeOptions="pageSizeOptions"
                :total="pageParams.totalCount"
                showSizeChanger
                :pageSize="pageParams.perPage"
                v-model="current"
                @change="changePage"
                @showSizeChange="onShowSizeChange"
                :showTotal="total => `共 ${pageParams.totalCount.toLocaleString()}条 `"
                >
                    <template slot='buildOptionText' slot-scope='props'>
                    <span v-if="props.value!=='100'">{{props.value}}条/页</span>
                    <span v-if="props.value==='100'">100条/页</span>
                    </template>
                </a-pagination>
            </div>
        </div>

        <contractPdf v-if="isContractPdf" :contractPdf='contractPdfPath' />

        <editModal v-if="isEditModal" :item='modalData' />
        <infoModal v-if="isInfoModal" :item='modalData' />
        <fileModal v-if="isFileModal" :item='modalData' />
        <detailModal v-if="isDetailModal" :item='modalData.apply_id' />
        <profileModal v-if="profileVisible" :studentId="studentId" :student_name='student_name' :contract_no='contract_no' :isRefresh="1"/>
    </div>
</template>

<script>
const columns = [
  { title: '#', width:'80px', scopedSlots: { customRender: 'index' } , key: 'index' ,fixed: 'left'},
  { title: '学员信息', width:'100px', dataIndex: 'student_name', key: 'student_name',scopedSlots: { customRender: 'user' },fixed: 'left'},
//   { title: '性别', width:'70px', dataIndex: 'student_gender', key: 'student_gender'},
  { title: '联系电话', width:'120px', dataIndex: 'student_cellphone', key: 'student_cellphone',fixed: 'left',scopedSlots: { customRender: 'cellphone' }},
  { title: '推送状态', width:'110px', dataIndex: 'is_notify', key: 'is_notify',scopedSlots: { customRender: 'is_notify' },sorter:()=>{},fixed: 'left'},
  { title: '订单号', dataIndex: 'out_trade_no', key: 'out_trade_no'},
  { title: '合同类型',width:'90px',  dataIndex: 'contract_type', key: 'contract_type',align:'center'},
  { title: '出生日期',width:'120px',  dataIndex: 'birthday', key: 'birthday',align:'center',sorter:()=>{}},
  { title: '所属校区', dataIndex: 'studio_id', key: 'studio_id',ellipsis:true,sorter:()=>{}},
  { title: '课程顾问', dataIndex: 'cc_id', key: 'cc_id'},
  { title: '课程科目', dataIndex: 'course_cate_id', key: 'course_cate_id',ellipsis:true},

  { title: '收费名称', dataIndex: 'fee_id', key: 'fee_id',scopedSlots: { customRender: 'fee' },sorter:()=>{},ellipsis:true},
  { title: '优惠名称', dataIndex: 'coupon_id', key: 'coupon_id',scopedSlots: { customRender: 'coupon' },sorter:()=>{},ellipsis:true},

  { title: '助单教练', dataIndex: 'suport_teacher_id', key: 'suport_teacher_id'},

  { title: '渠道人', dataIndex: 'suport_mkt_id', key: 'suport_mkt_id'},
  { title: '续费教练',width:'110px', dataIndex: 'extend_teacher_id', key: 'extend_teacher_id',sorter:()=>{}},
  { title: '优惠券', dataIndex: 'user_coupon_from', key: 'user_coupon_from',scopedSlots: { customRender: 'user_coupon_from' }},

  { title: '教具', dataIndex: 'ball_type', key: 'ball_type'},
  { title: '衣服尺码', dataIndex: 'suit_type', key: 'suit_type'},
  { title: '邮箱', dataIndex: 'email', key: 'email'},

  { title: '来源渠道',width:'110px',  dataIndex: 'channel_id', key: 'channel_id',ellipsis:true,sorter:()=>{}},
  { title: '收费金额',width:'110px',  dataIndex: 'charge_money', key: 'charge_money',align:"right",sorter:()=>{}},
//   { title: '签名', dataIndex: 'signature', key: 'signature',scopedSlots: { customRender: 'signature' }},
//   { title: '凭证截图', dataIndex: 'screen_shot', key: 'screen_shot',scopedSlots: { customRender: 'image' }},
  { title: '申请时间',width:'110px',  dataIndex: 'apply_time', key: 'apply_time',sorter:()=>{}},
  { title: '入读状态',width:'110px', dataIndex: 'apply_status', key: 'apply_status',scopedSlots: { customRender: 'apply_status' },sorter:()=>{}},
  { title: '开票状态',width:'110px', dataIndex: 'is_invoice', key: 'is_invoice',scopedSlots: { customRender: 'is_invoice' },sorter:()=>{}},
  { title: '发货状态',width:'110px', dataIndex: 'is_shipment', key: 'is_shipment',scopedSlots: { customRender: 'is_shipment' },sorter:()=>{}},
  { title: '家长备注', dataIndex: 'student_remark', key: 'student_remark',ellipsis: true},
  { title: '老师备注', dataIndex: 'remark', key: 'remark',ellipsis: true},
  { title: '操作',width:'90px', key: 'operation', fixed: 'right',align:"right", scopedSlots: { customRender: 'action' }}
]
import tableMixins from '@/common/mixins/table'
import ranges from "@/common/mixins/ranges"
import editModal from './editModal'
import infoModal from './infoModal'
import contractPdf from './contractPdf'
import detailModal from './detailModal'
import fileModal from './fileModal'
import profileModal from '@/views/modal/profile'
import moment from 'moment'
    export default {
        name:'huidongApply',
        mixins: [ tableMixins , ranges ],
        data() {
            return {
                badgeCount:0,
                loading: false,
                isEditModal: false,
                isInfoModal: false,
                isDetailModal: false,
                isFileModal: false,
                isContractPdf:false,
                contractPdfPath:'',
                exportLoading: false,
                profileVisible: false,
                columns,
                studios:[],
                consultants:[],
                is_invoice:'',
                paramsData:{
                    start_date:'',
                    end_date:'',
                },
                studentId:'',
                student_name:'',
                contract_no:'',
            }
        },
        components: {
            contractPdf,
            editModal,
            infoModal,
            fileModal,
            detailModal,
            profileModal
        },
        created () {
            this.searchParams.search.is_invoice = ''
            this.getStudio()
            this.getConsultantList()
        },
        methods: {
            filterOption(input, option) {
                return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
            },
            async getList() {
                this.loading = true
                this.searchParams.search.start_date = this.paramsData.start_date
                this.searchParams.search.end_date = this.paramsData.end_date
                let res = await this.$store.dispatch('huidongAction',this.searchParams)
                this.list = res.items
                this.pageParams = res._meta
                this.loading = false
            },
            async getStudio() {
                let res = await this.$store.dispatch('searchBelongStudioAction', {})
                this.studios = res.data
            },
            async getConsultantList() {
                let res = await this.$store.dispatch('searchConsultantAction', { })
                this.consultants = res.data
            },
            async pushContract(item){
                let that = this
                this.$confirm({
                    title: `您确定推送合同？`,
                    okText: '确定',
                    okType: 'danger',
                    cancelText: '取消',
                    onOk() {
                    return new Promise(async (resolve, reject) => {
                        let res = await that.$store.dispatch('huidongSendNotifyAction', {apply_id:item.apply_id})
                        if (res) {
                        that.getList()
                        that.$message.success('操作成功~')
                        resolve(res)
                        }
                    }).catch(error => console.log(error))
                    }
                })
            },
            handleTypeButChange(e){
                if(this.searchParams.search.contract_type == e.target.value){
                    this.searchParams.search.contract_type = ""
                }else{
                    this.searchParams.search.contract_type = e.target.value
                }
                this.getList();
            },
            handleColButChange(e){
                if(this.searchParams.search.is_invoice == e.target.value){
                    this.searchParams.search.is_invoice = ""
                }else{
                    this.searchParams.search.is_invoice = e.target.value
                }
                this.getList();
            },
            handleStatus(val){
                this.is_invoice = val
                this.searchParams.search.is_invoice = val
            },
            changeStatus(record) {
                // let obj = {apply_id:record.apply_id,student_name:record.student_name}
                // obj.invoice_status = 1
                // this.modalData = obj
                // this.isFileModal = true
                let obj = {apply_id:record.apply_id}
                 if (record.is_invoice == 1) {
                    obj.invoice_status = 0
                 } else {
                     obj.invoice_status = 1
                 }
                 this.confirmItem(obj)

            },
            changeShipment(record) {
                let obj = {apply_id:record.apply_id}
                if (record.is_shipment == 1) {
                    obj.is_shipment = 0
                } else {
                    obj.is_shipment = 1
                }
                this.confirmShipment(obj)
            },
            confirmItem(obj) {
                let that = this
                this.$confirm({
                    title: `您确定切换该状态？`,
                    okText: '确定',
                    okType: 'danger',
                    cancelText: '取消',
                    onOk() {
                    return new Promise(async (resolve, reject) => {
                        let res = await that.$store.dispatch('huidongCheckAction', obj)
                        if (res) {
                        that.getList()
                        that.$message.success('操作成功~')
                        resolve(res)
                        }
                    }).catch(error => console.log(error))
                    }
                })
            },
            confirmShipment(obj){
                let that = this
                this.$confirm({
                    title: `您确定切换该状态？`,
                    okText: '确定',
                    okType: 'danger',
                    cancelText: '取消',
                    onOk() {
                    return new Promise(async (resolve, reject) => {
                        let res = await that.$store.dispatch('huidongApplyUpdateAction', obj)
                        if (res) {
                        that.getList()
                        that.$message.success('操作成功~')
                        resolve(res)
                        }
                    }).catch(error => console.log(error))
                    }
                })
            },
            async toExport(item){
                this.exportLoading = true
                let exportData = {
                    search:this.searchParams.search,
                    sort:this.searchParams.sort
                }
                let res = await this.$store.dispatch('huidongExportAction', exportData)
                const blob = new Blob([res], {type: 'application/vnd.ms-excel'})
                if ('download' in document.createElement('a')) { 
                    const link = document.createElement('a')
                    link.download = `入读申请.${moment().format('YYYY.MM.DD')}.xlsx`
                    link.style.display = 'none'
                    link.href = URL.createObjectURL(blob)
                    document.body.appendChild(link)
                    link.click()
                    URL.revokeObjectURL(link.href) 
                    document.body.removeChild(link)
                } else { //其他浏览器
                    navigator.msSaveBlob(blob, fileName)
                }
                this.exportLoading = false
            },
            showEditModal(item){
                this.modalData = item
                this.isEditModal = true
            },
            hideEditModal(val){
                if(val){
                    this.getList()
                }
                this.isEditModal = false
            },
            hideFileModal(val){
                if(val){
                    this.getList()
                }
                this.isFileModal = false
            },
            showDetailModal(item){
                this.modalData = item
                this.isDetailModal = true
            },
            hideDetailModal(){
                this.isDetailModal = false
            },
            showInfoModal(item){
                this.modalData = item
                this.isInfoModal = true
            },
            hideInfoModal(){
                this.isInfoModal = false
            },
            showProfileVisible(item){
                this.contract_no = item.contract_no
                this.student_name = item.student_name
                this.studentId = item.student_id
                this.profileVisible = true
            },
            hideProfileModal(){
                this.profileVisible = false
            },
            handleChange(pagination, filters, sorter){
                if(sorter.order){
                    if(sorter.order === "ascend"){
                        this.searchParams['sort'] = `${sorter.field}`
                    }else{
                        this.searchParams['sort'] = `-${sorter.field}`
                    }
                    }else{
                    this.searchParams['sort'] = ''
                    }
                this.getList();
            },
            changeBadge(){
            let obj = {...this.searchParams.search}
            delete obj.start_date
            delete obj.end_date
            delete obj.nickname
            delete obj.is_invoice
            var _toString = Object.prototype.toString
            let sum = 0
            for(let i in obj){
                console.log(_toString.call(obj[i]).slice(8, -1))
                if(_toString.call(obj[i]).slice(8, -1) == 'Array' && obj[i].length >0){
                    sum++
                }
                if(_toString.call(obj[i]).slice(8, -1) == 'Number'){
                    sum++
                }
                if(_toString.call(obj[i]).slice(8, -1) == 'String' && obj[i]){
                    if(i == 'promise_start_date' || i == 'add_start_date' || i == 'attend_start_date' || i == 'next_start_date' || i == 'update_start_date' || i == 'distribute_start_date'){
                        sum++
                    }
                }
            }
            this.badgeCount = sum
        },
            clickRow(record, index) {
                return {
                    on: {
                        click: () => {
                            this.showDetailModal(record)
                        }
                    }
                }
            },
            async toPreviewPDF(contractPdfPath){
                // this.modalData = item
                this.contractPdfPath = contractPdfPath
                this.isContractPdf=true;
            },
            async toPreview(item){
                this.loading = true
                if(item.contract_link){
                    this.loading = false
                    // window.open(`https://view.officeapps.live.com/op/view.aspx?src=${encodeURIComponent(item.contract_link)}`)
                    if(item.contract_pdf){
                        this.toPreviewPDF(item.contract_pdf)
                    }else{
                        window.open(`https://view.officeapps.live.com/op/view.aspx?src=${item.contract_link}`)
                    }
                    // window.open(`https://view.xdocin.com/xdoc?_xdoc=${encodeURIComponent(item.contract_link)}`)
                    // window.open(`http://api.idocv.com/view/url?url=${encodeURIComponent(item.contract_link)}`)
                }else{
                    await this.$store.dispatch('huidongApplyPreviewAction', {apply_id:item.apply_id})
                    .then(res=>{
                        this.loading = false
                        // window.open(`https://view.officeapps.live.com/op/view.aspx?src=${encodeURIComponent(res.data.contract_link)}`)
                        
                        if(res.data.pdf_link){
                            this.toPreviewPDF(res.data.pdf_link)
                        }else{
                            window.open(`https://view.officeapps.live.com/op/view.aspx?src=${res.data.contract_link}`)
                        }
                        // window.open(`https://view.xdocin.com/xdoc?_xdoc=${encodeURIComponent(res.data.contract_link)}`)
                        // window.open(`http://api.idocv.com/view/url?url=${encodeURIComponent(res.data.contract_link)}`)
                    })
                }
            },
            toDel(item){
                let that = this
                this.$confirm({
                    title: `您确定删除该入读申请??`,
                    okText: '确定',
                    okType: 'danger',
                    cancelText: '取消',
                    onOk() {
                    return new Promise(async (resolve, reject) => {
                        await that.$store.dispatch('huidongDeleteAction', { apply_id:item.apply_id })
                        .then(res=>{
                            that.$message.success('操作成功~')
                            that.getList()
                            resolve(res)
                        })
                        .catch(err=>{
                            reject(err)
                        })
                    }).catch(error => console.log(error))
                    }
                })
            }
        },
    }
</script>

<style lang="scss" scoped>
    .coupon_title{
        width: 100px;
        overflow: hidden;
        text-overflow: ellipsis;
    }
</style>