<template>
  <a-modal title="填写报表" v-model="visible" :confirmLoading="confirmLoading"
    cancelText="取消" okText="提交" width="700px"
    @ok="handleOk" @cancel="handleCancel">
    <a-tabs v-model="active" @change="callback">
      <a-tab-pane key="1" tab="日报"></a-tab-pane>
      <a-tab-pane key="2" tab="周报"></a-tab-pane>
      <a-tab-pane key="3" tab="月报"></a-tab-pane>
    </a-tabs>
    <a-form :form="form">
      <a-form-item :label="labelName" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-textarea v-model="content" :rows="4" />
      </a-form-item>
      <a-form-item :label="labelNameTwo" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-textarea v-model="tomorrow" :rows="4" />
      </a-form-item>
      <a-form-item label="遇到的问题" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-textarea v-model="question" :rows="4" />
      </a-form-item>
      <a-form-item label="添加图片" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-upload
            accept='.png,.jpeg,.jpg'
            list-type="picture-card"
            :file-list="imgList"
            :data="uploadParams"
            :action="uploadUrl"
            :multiple='true'
            :beforeUpload="beforeUploadImg"
            @change="changeImg">
            <div>
                <a-icon type="plus" />
            </div>
        </a-upload>
      </a-form-item>
      <a-form-item label="发送给谁" :label-col="labelCol" :wrapper-col="wrapperCol">
        <div>
          <a-popover v-model="isCheckWarp" title="选择" trigger="click">
            <div slot="content">
              <checkWarp />
            </div>
            <a-tag v-for="(item,index) in check_name_list" :key="index">{{item.filter_name || item.department_name}}</a-tag>
            <a-icon style='fontSize:18px' type="plus-circle" />
          </a-popover>
        </div>
      </a-form-item>
    </a-form>
  
  </a-modal>
</template>

<script>
import url from '@/utils/URL'
import checkWarp from '../coachDaily/CheckWarp'
import createFilePath from '@/utils/tools'
export default {
  name: 'EditModal',
  inject: ['parent'],
  provide() {
      return {
          parent: this
      }
  },
  props: {
    item: Object
  },
  components: {
    checkWarp,
  },
  data() {
    return {
      labelCol: { span: 5 },
      wrapperCol: { span: 17 },
      confirmLoading: false,
      visible: false,
      isCheckWarp: false,
      form: this.$form.createForm(this),
      active:'1',
      labelName:'今日工作',
      labelNameTwo:'明日工作',
      imgList:[],
      imgAddr:[],
      uploadParams: {},
      uploadUrl: url.uploadQiNiu,
      content:'',
      tomorrow:'',
      question:'',
      check_name_list:[],
    }
  },

  async created() {
    this.visible = true
    this.$ls.remove('check_name_list')
    this.$ls.remove('send_employee_ids')
    this.$ls.remove('send_employee_list')
    this.$ls.remove('send_structure_ids')
    this.$ls.remove('send_structure_list')
  },

  methods: {
    async handleOk() {
      let send_employee_ids = JSON.parse(localStorage.getItem('send_employee_ids')) || []
      let send_structure_ids = JSON.parse(localStorage.getItem('send_structure_ids')) || []
      let images = []
      this.imgList.forEach(item=>{
        images.push(item.response.key)
      })
      let obj = {
        content:this.content,
        tomorrow:this.tomorrow,
        question:this.question,
        category_id:this.active
      }
      if(images.length > 0){
        obj.images = images
      }
      if(send_employee_ids.length > 0){
        obj.send_employee_ids = send_employee_ids
      }
      if(send_structure_ids.length > 0){
        obj.send_structure_ids = send_structure_ids
      }
      await this.$store.dispatch('oaAddAction', obj)
      .then(res=>{
        this.$message.success('操作成功~')
      })
      .catch(err=>{
        this.$message.error('操作失败~')
      })

    },
    async beforeUploadImg(file,fileList) {
        if (!file) { return false }
        let res = await this.$store.dispatch('imageTokenAction', {})
        if (res) {
            this.uploadParams.token = res.data.uptoken
            this.uploadParams.key = createFilePath(file.name)
            let obj = {
                uid:file.uid,
                url:this.uploadParams.key,
            }
            this.imgAddr.push(obj)
            return true
        }
        return false
    },
    async changeImg({fileList}){
      var arr = fileList
      arr.forEach((item,index)=>{
          var isJpgOrPng = item.type === 'image/jpeg' || item.type === 'image/png';
          if(!isJpgOrPng){
              arr.splice(index,1)
          }
      })
      this.imgList = arr
    },
    hideCheckWarp(val){
      if(val){
        this.check_name_list = JSON.parse(localStorage.getItem('check_name_list'))
      }
      this.isCheckWarp = false
    },
    callback(e){
      this.content = ''
      this.tomorrow = ''
      this.question = ''
      if(e === '1'){
        this.labelName = '今日工作'
        this.labelNameTwo = '明日工作'
      }
      if(e === '2'){
        this.labelName = '本周工作'
        this.labelNameTwo = '下周工作'
      }
      if(e === '3'){
        this.labelName = '本月工作'
        this.labelNameTwo = '下月工作'
      }
    },
    handleCancel() {
      this.parent.hideEditModal()
    }
  },
}
</script>
