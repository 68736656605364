<template>
    <div>
        <div class="main-box">
            <div class="clearfix table-tools">
                <div class="search">
                    <a-form layout='inline'>
                        <a-form-item>
                            <a-tooltip placement="topLeft" >
                            <template slot="title">
                                <span>时间筛选</span>
                            </template>
                            <a-range-picker
                                :ranges="rangesData"
                                :value='rangesValue'
                                :disabled-date="disabledDate"
                                :allowClear="false"
                                @change="(date, dateString)=>onChange(date, dateString,3)" />
                            </a-tooltip>
                        </a-form-item>
                        <a-form-item>
                            <a-button type="primary" @click="getList">筛选</a-button>
                        </a-form-item>
                    </a-form>
                </div>
                <div class="buttons"></div>
            </div>
            <div class="table huidong-table">
                <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
                <a-table :pagination="false" :bordered='true' rowKey="id"
                :columns="columns" :dataSource="list">
                    <template slot="name" slot-scope="text , record">
                        <div class="huidong-col-bg" v-if="record.isHead">{{text}}</div>
                        <div v-else class="huidong-col">
                            <span>{{text}}</span>
                        </div>
                    </template>
                    <template slot="no" slot-scope="text , record">
                        <div class="huidong-col-bg" v-if="record.isHead">{{text}}</div>
                        <div v-else class="huidong-col">
                            <span>{{text}}</span>
                            <!-- <div class="huidong-col-no" :style="{width:'10%'}"></div> -->
                        </div>
                    </template>
                    <template slot="none" slot-scope="text , record">
                        <div class="huidong-col-bg" v-if="record.isHead">{{text}}</div>
                        <div v-else class="huidong-col">
                            <span>{{text}}</span>
                            <!-- <div class="huidong-col-none" :style="{width:'20%'}"></div> -->
                        </div>
                    </template>
                    <template slot="loading" slot-scope="text , record">
                        <div class="huidong-col-bg" v-if="record.isHead">{{text}}</div>
                        <div v-else class="huidong-col">
                            <span>{{text}}</span>
                            <!-- <div class="huidong-col-loading" :style="{width:'30%'}"></div> -->
                        </div>
                    </template>
                    <template slot="order" slot-scope="text , record">
                        <div class="huidong-col-bg" v-if="record.isHead">{{text}}</div>
                        <div v-else class="huidong-col">
                            <span>{{text}}</span>
                            <!-- <div class="huidong-col-order" :style="{width:'40%'}"></div> -->
                        </div>
                    </template>
                    <template slot="unconnect" slot-scope="text , record">
                        <div class="huidong-col-bg" v-if="record.isHead">{{text}}</div>
                        <div v-else class="huidong-col">
                            <span>{{text}}</span>
                            <!-- <div class="huidong-col-unconnect" :style="{width:'50%'}"></div> -->
                        </div>
                    </template>
                    <template slot="long" slot-scope="text , record">
                        <div class="huidong-col-bg" v-if="record.isHead">{{text}}</div>
                        <div v-else class="huidong-col">
                            <span>{{text}}</span>
                            <!-- <div class="huidong-col-long" :style="{width:'60%'}"></div> -->
                        </div>
                    </template>
                    <template slot="done" slot-scope="text , record">
                        <div class="huidong-col-bg" v-if="record.isHead">{{text}}</div>
                        <div v-else class="huidong-col">
                            <span>{{text}}</span>
                            <!-- <div class="huidong-col-done" :style="{width:'70%'}"></div> -->
                        </div>
                    </template>
                    <template slot="invalid" slot-scope="text , record">
                        <div class="huidong-col-bg" v-if="record.isHead">{{text}}</div>
                        <div v-else class="huidong-col">
                            <span>{{text}}</span>
                            <!-- <div class="huidong-col-invalid" :style="{width:'80%'}"></div> -->
                        </div>
                    </template>
                    <template slot="putAside" slot-scope="text , record">
                        <div class="huidong-col-bg" v-if="record.isHead">{{text}}</div>
                        <div v-else class="huidong-col">
                            <span>{{text}}</span>
                            <!-- <div class="huidong-col-putAside" :style="{width:'100%'}"></div> -->
                        </div>
                    </template>
                </a-table>
            </div>
            <!-- <div class="page">
                <a-pagination
                :pageSizeOptions="pageSizeOptions"
                :total="pageParams.totalCount"
                showSizeChanger
                :pageSize="pageParams.perPage"
                v-model="current"
                @change="changePage"
                @showSizeChange="onShowSizeChange"
                :showTotal="total => `共 ${pageParams.totalCount.toLocaleString()}条 `"
                >
                    <template slot='buildOptionText' slot-scope='props'>
                    <span v-if="props.value!=='100'">{{props.value}}条/页</span>
                    <span v-if="props.value==='100'">100条/页</span>
                    </template>
                </a-pagination>
            </div> -->
        </div>
    </div>
</template>

<script>
const columns = [
  { title: '校区', width:'200px', dataIndex: 'studio_name', key: 'studio_name',align:'center',scopedSlots: { customRender: 'name' }},
  { title: '未跟进', dataIndex: '未跟进', key: '2',align:'center',scopedSlots: { customRender: 'no' }},
//   { title: '暂无', dataIndex: '暂无', key: '3',align:'center',scopedSlots: { customRender: 'none' }},
  { title: '跟进中', dataIndex: '跟进中', key: '4',align:'center',scopedSlots: { customRender: 'loading' }},
  { title: '已预约', dataIndex: '已预约', key: '5',align:'center',scopedSlots: { customRender: 'order' }},
  { title: '未接通', dataIndex: '未接通', key: '6',align:'center',scopedSlots: { customRender: 'unconnect' }},
  { title: '长期跟进', dataIndex: '长期跟进', key: '7',align:'center',scopedSlots: { customRender: 'long' }},
  { title: '已成单', dataIndex: '已成单', key: '8',align:'center',scopedSlots: { customRender: 'done' }},
  { title: '无效', dataIndex: '无效', key: '9',align:'center',scopedSlots: { customRender: 'invalid' }},
  { title: '暂搁', dataIndex: '暂搁', key: '10',align:'center',scopedSlots: { customRender: 'putAside' }},
]

    import moment from 'moment'
    import ranges from "@/common/mixins/ranges"
    export default {
        name:'stateDistribution',
        mixins: [ ranges ],
        data() {
            return {
                columns,
                list:[],
                loading: false,
                paramsData:{
                    start_date:moment().startOf('month').format('YYYY-MM-DD'),
                    end_date:moment().format('YYYY-MM-DD'),
                },
                searchParams: {
                    "search": {},
                },
            }
        },
        created(){
            this.rangesValue = [moment(this.paramsData.start_date,'YYYY-MM-DD'),moment(this.paramsData.end_date,'YYYY-MM-DD')]
            this.getList()
        },
        methods: {
            async getList(){
                this.loading = true
                this.searchParams.search.start_date = this.paramsData.start_date
                this.searchParams.search.end_date = this.paramsData.end_date
                let res = await this.$store.dispatch('huidongReportChannelAction',this.searchParams)
                this.list = this.screenList(res.data)
                this.loading = false
            },
            screenList(arr){
                let list = []
                arr.forEach((item,index)=>{
                    let num = index + 1
                    let obj = { id:num, studio_name:item.channel_name, isHead:1}
                    item.follow_status.forEach(it=>{
                        obj[it.step_text] = it.total
                    })
                    list.push(obj)
                    item.sub_studio.forEach((it,i)=>{
                        // let number = `${index+1}${i+1}`
                        let number = Number(`${index+1}${i+1}`)
                        let objChild = { id:number, studio_name:it.studio_name }
                        it.follow_status.forEach(em=>{
                            objChild[em.step_text] = em.total
                        })
                        list.push(objChild)
                    })
                })
                return list
            },
        },
    }
</script>

<style lang="scss">
    .huidong-col{
        position: relative;
        div{
            position: absolute;
            height: 22px;
            top: -2px;
            left: 0;
        }
        &-no{
            background: rgba(34,56,67,0.4);
        }
        &-none{
            background: rgba(125, 14, 129, 0.4);
        }
        &-loading{
            background: rgba(180, 4, 48, 0.4);
        }
        &-order{
            background: rgba(31, 218, 25, 0.4);
        }
        &-unconnect{
            background: rgba(230, 226, 25, 0.4);
        }
        &-long{
            background: rgba(71, 219, 212, 0.4);
        }
        &-done{
            background: rgba(122, 12, 150, 0.4);
        }
        &-invalid{
            background: rgba(219, 93, 8, 0.363);
        }
        &-putAside{
            background: rgba(236, 7, 7, 0.363);
        }
    }
    .huidong-col-bg{
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        position: absolute;
        background: #fafafa;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .huidong-table{
        th{
            width: 130px;
        }
        td{
            position: relative;
        }
    }
</style>