<template>
  <a-modal title="生成订单信息" v-model="visible" cancelText="取消" okText="下载" width="550px"
    @ok="handleOk" @cancel="handleCancel" class="survey-qrcode">
        <div :style="{height:wHeight}" class="hdgl-qrcode">
            <vue-canvas-poster :widthPixels="1000" :painting="painting" @success="success" @fail="fail"></vue-canvas-poster>
        </div>
  </a-modal>
</template>

<script>
    import { VueCanvasPoster } from 'vue-canvas-poster'
    export default {
        name:'qrcodeModal',
        inject: ['parent'],
        data() {
            return {
                visible: false,
                painting: {},
                active:1,
                imgurl:'',
                wHeight:'550px'
            }
        },
        props:{
            item:Object,
            isImg:Number
        },
        components: {
            VueCanvasPoster,
        },
        created(){
            this.visible = true
            this.painting = {
                width: '250px',
                height: '250px',
                background: '#f4f5f7',
                views: [
                    {
                        type: 'text',
                        text: '昂立慧动',
                        css:[
                            {
                                fontSize:'20px',
                                top:'15px',
                                left:'85px',
                            }
                        ]
                    },
                    {
                        type: 'text',
                        text: `用户名# ${this.item.student_name}`,
                        css:[
                            {
                                fontSize:'10px',
                                top:'60px',
                                left:'10px'
                            }
                        ]
                    },
                    {
                        type: 'text',
                        text: `订单号# ${this.item.out_trade_no}`,
                        css:[
                            {
                                fontSize:'10px',
                                top:'75px',
                                left:'10px'
                            }
                        ]
                    },
                    {
                        type: 'rect',
                        css:[
                            {
                                color: '#eee',
                                width: '230px',
                                height: '1px',
                                top:'95px',
                                left:'10px'
                            }
                        ]
                    },
                    {
                        type: 'text',
                        text: `${this.item.fee_id.fee_title}`,
                        css:[
                            {
                                fontSize:'10px',
                                top:'100px',
                                left:'10px'
                            }
                        ]
                    },
                    {
                        type: 'text',
                        text: `¥${this.item.charge_money}`,
                        css:[
                            {
                                fontSize:'10px',
                                top:'100px',
                                right:'10px'
                            }
                        ]
                    },
                    {
                        type: 'rect',
                        css:[
                            {
                                width: '230px',
                                height: '2px',
                                top:'115px',
                                left:'10px'
                            }
                        ]
                    },
                    {
                        type: 'text',
                        text: '合计',
                        css:[
                            {
                                fontSize:'10px',
                                top:'120px',
                                right:'90px',
                            }
                        ]
                    },
                    {
                        type: 'text',
                        text: `¥${this.item.charge_money}`,
                        css:[
                            {
                                fontSize:'10px',
                                top:'120px',
                                right:'10px'
                            }
                        ]
                    },
                    {
                        type: 'rect',
                        css:[
                            {
                                width: '230px',
                                height: '2px',
                                top:'135px',
                                left:'10px'
                            }
                        ]
                    },
                    {
                        type: 'text',
                        text: `支付方式 ${this.item.out_trade_no}`,
                        css:[
                            {
                                fontSize:'10px',
                                top:'145px',
                                left:'10px'
                            }
                        ]
                    },
                    {
                        type: 'text',
                        text: '支付状态 SUCCESS',
                        css:[
                            {
                                fontSize:'10px',
                                top:'160px',
                                left:'10px'
                            }
                        ]
                    },
                    {
                        type: 'text',
                        text: `联系电话 ${this.item.student_cellphone}`,
                        css:[
                            {
                                fontSize:'10px',
                                top:'175px',
                                left:'10px'
                            }
                        ]
                    },
                    {
                        type: 'text',
                        text: `支付时间 ${this.item.apply_time}`,
                        css:[
                            {
                                fontSize:'10px',
                                top:'190px',
                                left:'10px'
                            }
                        ]
                    },
                    {
                        type: 'text',
                        text: `支付号码# ${this.item.out_trade_no}`,
                        css:[
                            {
                                fontSize:'10px',
                                top:'205px',
                                left:'10px'
                            }
                        ]
                    },
                    
                ],
            }
        },
        methods: {
            handleOk() {
                this.downloadFile('活动.png',this.imgurl)
            },
            handleCancel(){this.parent.hideInfoModal()},
            success(src) {
                this.imgurl = src
            },
            fail(err) {
                console.log('fail', err)
            },
            downloadFile(fileName, content) {
                let aLink = document.createElement('a');
                let blob = this.base64ToBlob(content); //new Blob([content]);
                let evt = document.createEvent("HTMLEvents");
                evt.initEvent("click", true, true);//initEvent 不加后两个参数在FF下会报错  事件类型，是否冒泡，是否阻止浏览器的默认行为
                aLink.download = fileName;
                aLink.href = URL.createObjectURL(blob);
                aLink.dispatchEvent(new MouseEvent('click', {bubbles: true, cancelable: true, view: window}));//兼容火狐
            },
            base64ToBlob(code) {
                let parts = code.split(';base64,');
                let contentType = parts[0].split(':')[1];
                let raw = window.atob(parts[1]);
                let rawLength = raw.length;

                let uInt8Array = new Uint8Array(rawLength);

                for (let i = 0; i < rawLength; ++i) {
                uInt8Array[i] = raw.charCodeAt(i);
                }
                return new Blob([uInt8Array], {type: contentType});
            },
        }
    }
</script>

<style lang="scss">
    .survey-qrcode{
        .ant-modal-body{
            max-height: 2000px;
        }
    }
    .hdgl-qrcode{
        .canvas{
            position: absolute !important;
            top:inherit !important;
            width: 500px !important;
            height: 500px !important;
        }
    }
</style>