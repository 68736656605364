<template>
  <a-modal title="入读信息编辑" v-model="visible" :confirmLoading="confirmLoading"
    cancelText="取消" okText="保存" width="700px" :zIndex='4100'
    @ok="handleOk" @cancel="handleCancel">
    <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
    <a-form :form="form">
        <a-row>
            <a-col :span="item.type === 'textarea'?24:12" v-for="(item, index) of formData" :key="index" >
                <a-form-item v-if="item.type !== 'textarea'" :label="item.label" :label-col="{ span: 8 }" :wrapper-col="{ span: 15 }">
                    <a-input v-if="item.type === 'input'" v-decorator="[item.name, { rules: item.rules }]"/>
                    <a-input-number style="width:100%" v-if="item.type === 'inputNumber'" :min="0" v-decorator="[item.name, { rules: item.rules }]"/>
                    <a-date-picker v-if="item.type === 'date'" style="width: 100%" :popupStyle='{zIndex:4200}'  v-decorator="[item.name, { rules: item.rules }]" format="YYYY-MM-DD"/>

                    <a-select v-else-if="item.type === 'select'" placeholder="请选择" :dropdownStyle='{zIndex:4200}'
                    v-decorator="[item.name, { rules: item.rules }]" showSearch :filterOption="filterOption">
                    <a-select-option v-for="(d, index) of seleteItems[item.items.data]" :key="index" :value="d[item.items.value]">{{ d[item.items.label] }}</a-select-option>
                    </a-select>
                    <a-select v-else-if="item.type === 'selects'" placeholder="请选择" :dropdownStyle='{zIndex:4200}'
                    v-decorator="[item.name, { rules: item.rules }]" showSearch :filterOption="filterOption">
                    <a-select-option v-for="(d, index) of seleteItems[item.items.data]" :key="index" :value="d[item.items.value]">{{ d[item.items.label] }}</a-select-option>
                    </a-select>
                    <a-radio-group v-else-if="item.type === 'radio'" placeholder="请选择" v-decorator="[item.name, { rules: item.rules }]">
                    <a-radio v-for="(d, index) of seleteItems[item.items.data]" :key="index" :value="d[item.items.value]">{{ d[item.items.label] }}</a-radio>
                    </a-radio-group >
                    <a-cascader
                    v-else-if="item.type === 'cascader'"
                    :options="seleteItems[item.items.data]"
                    :show-search="{ filterOption }"
                    :value='channelsId'
                    placeholder="请选择"
                    :popupStyle='{zIndex:4200}'
                    @change="onChangeCascader">
                        <template slot="displayRender" slot-scope="{labels}">
                            <span>{{labels[1]}}</span>
                        </template>
                    </a-cascader>
                    <a-cascader
                    :popupStyle='{zIndex:4200}'
                    v-if="item.type === 'distpicker'"
                    :options="chinaDivision"
                    placeholder="请选择"
                    v-model="chinaDivisionValue"
                    @change="onChangeOptions"/>
                </a-form-item>
                <a-form-item v-if="item.type === 'textarea'" :label="item.label" :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }">
                  <a-textarea :rows="4" v-decorator="[item.name, { rules: item.rules }]"/>
                </a-form-item>
            </a-col>
            <a-col :span="24">
              <a-form-item label="付款凭证" :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }">
                <div>
                  <a-upload
                      accept='.png,.jpeg,.jpg'
                      list-type="picture-card"
                      :fileList="fileList"
                      :data="uploadParams"
                      :action="uploadUrl"
                      :multiple='true'
                      :beforeUpload="beforeUpload"
                      @change="changeFile">
                      <div>
                          <a-icon type="plus" />
                      </div>
                  </a-upload>
                </div>
              </a-form-item>
            </a-col>
        </a-row>
      
    </a-form>
  </a-modal>
</template>

<script>
import editModal from '@/common/mixins/editModal'
import C_ITEMS from '@/utils/items'
import createFilePath from '@/utils/tools'
import moment from 'moment'
import url from '@/utils/URL'
import chinaOptions from '@/utils/chinaDivision'

function phoneCheck (rule, value, callbackFn) {
          const reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/
          if (!reg.test(value)) {
            callbackFn('请输入正确手机号码!')
            return
          }
          callbackFn()
        }

const formData = [
  {
    name: 'nickname',
    label: '学员名称',
    type: 'input',
    rules: [{ required: true, message: '请输入学员名称!' }]
  },
  {
    name: 'charge_money',
    label: '订单金额',
    type: 'inputNumber',
    rules: []
  },
  {
    name: 'gender',
    label: '线索性别',
    type: 'radio',
    rules: [],
    items: {
      data: 'genderList',
      label: 'gender_name',
      value: 'gender_id'
    }
  },
  {
    name: 'credential_number',
    label: '证件号码',
    type: 'input',
    rules: [{ required: true, message: '请输入证件号码!' }]
  },
  {
    name: 'birthday',
    label: '出生日期',
    type: 'date',
    rules: [{ required: true, message: '请选择出生日期!' }]
  },
  {
    name: 'height',
    label: '学员身高',
    type: 'input',
    rules: []
  },
  {
    name: 'weight',
    label: '学员体重',
    type: 'input',
    rules: []
  },
  {
    name: 'contact_name',
    label: '联系人姓名',
    type: 'input',
    rules: [{ required: true, message: '请输入联系人姓名!' }]
  },
  {
    name: 'cellphone',
    label: '联系电话',
    type: 'input',
    rules: [{ required: true, message: '请输入联系电话!' }]
  },
  {
    name: 'contact_type',
    label: '联系人关系',
    type: 'selects',
    rules: [],
    items: {
      data: 'studentContactTypes',
      label: 'label',
      value: 'value'
    }
  },
  {
    name: 'email',
    label: '联系人邮箱',
    type: 'input',
    rules: [{ required: true, message: '请输入联系人邮箱!' }]
  },
  // {
  //   name: 'province',
  //   label: '省/市/区',
  //   type: 'distpicker',
  //   rules: [{ required: true, message: '请选择省/市/区!' }]
  // },
  {
    name: 'address',
    label: '收件地址',
    type: 'input',
    rules: []
  },
  {
    name: 'school',
    label: '就读学校',
    type: 'input',
    rules: []
  },
  {
    name: 'studio_id',
    label: '所属校区',
    type: 'select',
    rules: [{ required: true, message: '请选择所属校区!' }],
    items: {
      data: 'studios',
      label: 'filter_name',
      value: 'studio_id'
    }
  },
  {
    name: 'cc_id',
    label: '课程顾问',
    type: 'select',
    rules: [{ required: true, message: '请选择课程顾问!' }],
    items: {
      data: 'consultants',
      label: 'filter_name',
      value: 'course_consultant_id'
    }
  },
  {
    name: 'suport_teacher_id',
    label: '助单教练',
    type: 'select',
    rules: [{ required: false, message: '请选择助单教练!' }],
    items: {
      data: 'consultants',
      label: 'filter_name',
      value: 'course_consultant_id'
    }
  },
  {
    name: 'extend_teacher_id',
    label: '续费教练',
    type: 'select',
    rules: [{ required: false, message: '请选择续费教练!' }],
    items: {
      data: 'consultants',
      label: 'filter_name',
      value: 'course_consultant_id'
    }
  },
  {
    name: 'contract_type',
    label: '合同类型',
    type: 'select',
    rules: [],
    items: {
      data: 'contractTypes',
      label: 'filter_name',
      value: 'contract_type'
    }
  },
  {
    name: 'channel_id',
    label: '来源渠道',
    type: 'cascader',
    rules: [{ required: true, message: '请选择来源渠道!' }],
    items: {
      data: 'channels',
      label: 'filter_name',
      value: 'source_channel_id'
    }
  },
  {
    name: 'suport_mkt_id',
    label: '渠道人',
    type: 'select',
    rules: [{ required: false, message: '请选择渠道人!' }],
    items: {
      data: 'consultants',
      label: 'filter_name',
      value: 'course_consultant_id'
    }
  },
  {
    name: 'course_cate_id',
    label: '课程科目',
    type: 'selects',
    rules: [],
    items: {
      data: 'classCategorys',
      label: 'filter_name',
      value: 'course_cate_id'
    }
  },
  {
    name: 'payment_type',
    label: '支付方式',
    type: 'select',
    rules: [{ required: true, message: '请选择支付方式!' }],
    items: {
      data: 'payments',
      label: 'filter_name',
      value: 'payment_type'
    }
  },
  {
    name: 'ball_type',
    label: '教具',
    type: 'select',
    rules: [],
    items: {
      data: 'ballType',
      label: 'label',
      value: 'value'
    }
  },
  {
    name: 'suit_type',
    label: '衣服尺码',
    type: 'select',
    rules: [],
    items: {
      data: 'suitType',
      label: 'label',
      value: 'value'
    }
  },
  {
    name: 'apply_status',
    label: '入读状态',
    type: 'select',
    rules: [],
    items: {
      data: 'applyStatusList',
      label: 'label',
      value: 'value'
    }
  },
  {
    name: 'student_remark',
    label: '学员备注',
    type: 'textarea',
    rules: []
  },
  {
    name: 'remark',
    label: '老师备注',
    type: 'textarea',
    rules: []
  },
  // {
  //   name: 'screen_shot',
  //   label: '付款凭证',
  //   type: 'upload',
  //   rules: [{ required: true, message: '请选择课程顾问!' }],
  // },
]

export default {
  name: 'EditModal',
  mixins: [ editModal ],
  provide() {
    return {
      parent: this
    }
  },
  data() {
    return {
      loading:false,
      formData,
      seleteItems: {
        ballType:C_ITEMS.ballType,
        suitType:C_ITEMS.suitTypeNew,
        payments:[],
        studios:[],
        channels:[],
        consultants:[],
        contractTypes:[],
        classCategorys:[],
        studentContactTypes:C_ITEMS.studentContactTypes,
        genderList:[{gender_name:'男',gender_id:1},{gender_name:'女',gender_id:2},{gender_name:'未知',gender_id:0}],
        applyStatusList:[{label:'成功合同(未推送合同)',value:2},{label:'成功合同(已推送合同)',value:3},{label:'退费',value:4}]
      },
      modalTitle:'',
      fileList:[],
      imgAddr:[],
      channelsId:[],
      uploadParams: {},
      uploadUrl: url.uploadQiNiu,
      chinaDivision:chinaOptions,
      chinaDivisionValue:[],
      chinaDivisionValueL:[],
    }

  },

  async created() {
      this.visible = true
      this.loading = true
      this.getStudio()
      this.getPaymentType()
      this.getConsultant()
      this.getContractType()
      this.getClassCategory()
      let info = await this.getDetail()
      let obj = {}
      this.formData.forEach(item=>{
        if(item.name == 'birthday'){
          obj[item.name] = moment(info[item.name])
        }else{
          obj[item.name] = info[item.name]
        }
      })
      await this.$nextTick()
      delete obj.channel_id
      this.form.setFieldsValue(obj)
      this.getSourceChannel(info.channel_id)
      info.screen_shot.forEach((it,ind)=>{
        let obj = {
          uid: `-${ind+1}`,
          name: `-${ind+1}.png`,
          status: 'done',
          url: it,
          thumbUrl: it,
          response:{
            key:it
          }
        }
        this.fileList.push(obj)
      })
  },
  methods: {
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    async getDetail(){
      let res = await this.$store.dispatch('huidongApplyDetailAction', {apply_id:this.item.apply_id})
      return res.data
    },
    async getStudio() {
      let res = await this.$store.dispatch('searchBelongStudioAction', {})
      this.seleteItems.studios = res.data
    },
    async getConsultant() {
      let res = await this.$store.dispatch('searchConsultantAction', {})
      this.seleteItems.consultants = res.data
    },
    async getClassCategory() {
      let res = await this.$store.dispatch('searchCourseTypeAction', {})
      this.seleteItems.classCategorys = res.data
    },
    async getPaymentType() {
      let res = await this.$store.dispatch('searchPaymentTypeAction', {})
      this.seleteItems.payments = res.data
    },
    async getContractType() {
      let res = await this.$store.dispatch('searchContractTypeAction', {})
      this.seleteItems.contractTypes = res.data
    },
    async getSourceChannel(id) {
      let res = await this.$store.dispatch('searchSourceChannelCascaderAction', { })
      this.loading = false
      this.seleteItems.channels = res.data[0].children
      if(id){
        res.data[0].children.forEach(item=>{
          item.children.forEach(it=>{
            if(it.value == id){
              this.channelsId = [item.value,it.value]
            }
          })
        })
      }
    },
    async handleOk() {
      const { item, form } = this
      let params = await form.validateFields()
      this.confirmLoading = true
      params.apply_id = this.item.apply_id
      if(this.fileList.length > 0){
          let arr = []
          this.fileList.forEach(item=>{
            arr.push(item.response.key)
          })
          params.screen_shot = arr
      }
      params.channel_id = this.channelsId[1]
      if(params.birthday){
        params.birthday = moment(params.birthday).format('YYYY-MM-DD')
      }
      await this.$store.dispatch('huidongApplyUpdateAction', params)
      .then(res=>{
          this.$message.success('操作成功!')
          form.resetFields()
          this.parent.hideEditModal(1)
      }).catch(err=>{
          this.confirmLoading = false
      })
    },
    async beforeUpload(file,fileList) {
      if (!file) { return false }
      let res = await this.$store.dispatch('imageTokenAction', {})
      if (res) {
          this.uploadParams.token = res.data.uptoken
          this.uploadParams.key = createFilePath(file.name)
          let obj = {
              uid:file.uid,
              url:this.uploadParams.key,
          }
          this.imgAddr.push(obj)
          return true
      }
      return false
    },
    async changeFile(file){
        this.fileList = file.fileList
    },
    handleCancel(){
      this.parent.hideEditModal()
    },
    onChangeCascader(value, selectedOptions) {
        this.channelsId = value
    },
    onChangeOptions(a,b){
      this.chinaDivisionValueL = b
    },
  }
}
</script>