<template>
    <div>
        <a-breadcrumb class="breadcrumb">
            <a-breadcrumb-item>首页</a-breadcrumb-item>
            <a-breadcrumb-item>市场中心</a-breadcrumb-item>
            <a-breadcrumb-item>昂立慧动</a-breadcrumb-item>
        </a-breadcrumb>
        <div class="main-body students">
            <a-tabs :animated="false" :activeKey="activeKey" @change="changeTab">
                <a-tab-pane tab="入学申请" key="1">
                    <apply v-if="activeKey==='1'"/>
                </a-tab-pane>
                <a-tab-pane tab="教练日报" key="2">
                    <coachDaily v-if="activeKey==='2'"/>
                </a-tab-pane>
                <a-tab-pane tab="校区日报" key="3">
                    <campusDaily v-if="activeKey==='3'"/>
                </a-tab-pane>
                <a-tab-pane tab="教练周报" key="4">
                    <coachWeek v-if="activeKey==='4'"/>
                </a-tab-pane>
                <a-tab-pane tab="校区周报" key="5">
                    <campusWeek v-if="activeKey==='5'"/>
                </a-tab-pane>
                <a-tab-pane tab="状态分布" key="6">
                    <stateDistribution v-if="activeKey==='6'"/>
                </a-tab-pane>
                <a-tab-pane tab="热例子分布" key="7">
                    <hotLeads v-if="activeKey==='7'"/>
                </a-tab-pane>
                <a-tab-pane tab="热例子跟进状态" key="8">
                    <hotLeadsState v-if="activeKey==='8'"/>
                </a-tab-pane>
            </a-tabs>
        </div>
    </div>
</template>

<script>
    import apply from './apply/index'
    import coachDaily from './coachDaily/index'
    import campusDaily from './campusDaily/index'
    import campusWeek from './campusWeek/index'
    import coachWeek from './coachWeek/index'
    import stateDistribution from './stateDistribution/index'
    import hotLeads from './hotLeads/index'
    import hotLeadsState from './hotLeadsState/index'

    export default {
        name:'huidong',
        data() {
            return {
                activeKey: '1'
            }
        },
        components: {
            apply,
            coachDaily,
            campusDaily,
            coachWeek,
            campusWeek,
            hotLeads,
            hotLeadsState,
            stateDistribution
        },
        methods: {
            changeTab(key) {
                this.activeKey = key
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>